@media screen and (max-width: 400px) {
  #about,
  #county,
  #contact,
  #footer {
    width: 111%;
  }

  #portfolio {
    width: 110%;
  }
}


.text-left-paragraph { 
  text-align: left;
  margin-bottom: 25px; 
  font-size: large;
}


.endorse-list {
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
  font-size: medium;
}

.wrapdiv {
  overflow-wrap: break-word;
}

#wrapdiv {
  overflow-wrap: break-word;
}
